.page {
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  width: 100vw;
  height: 100vh;
  background: -moz-linear-gradient(
    0.82% 0.62% -29.46deg,
    rgba(140, 0, 0, 1) 1.73%,
    rgba(141, 0, 0, 1) 2.71%,
    rgba(180, 1, 0, 1) 31.82%,
    rgba(207, 1, 0, 1) 58.67%,
    rgba(224, 2, 0, 1) 82.26%,
    rgba(230, 2, 0, 1) 100%
  );
  background: -webkit-linear-gradient(
    -29.46deg,
    rgba(140, 0, 0, 1) 1.73%,
    rgba(141, 0, 0, 1) 2.71%,
    rgba(180, 1, 0, 1) 31.82%,
    rgba(207, 1, 0, 1) 58.67%,
    rgba(224, 2, 0, 1) 82.26%,
    rgba(230, 2, 0, 1) 100%
  );
  background: -webkit-gradient(
    linear,
    0.82% 0.62%,
    99.31% 99.51%,
    color-stop(0.0173, rgba(140, 0, 0, 1)),
    color-stop(0.0271, rgba(141, 0, 0, 1)),
    color-stop(0.3182, rgba(180, 1, 0, 1)),
    color-stop(0.5867, rgba(207, 1, 0, 1)),
    color-stop(0.8226, rgba(224, 2, 0, 1)),
    color-stop(1, rgba(230, 2, 0, 1))
  );
  background: -o-linear-gradient(
    -29.46deg,
    rgba(140, 0, 0, 1) 1.73%,
    rgba(141, 0, 0, 1) 2.71%,
    rgba(180, 1, 0, 1) 31.82%,
    rgba(207, 1, 0, 1) 58.67%,
    rgba(224, 2, 0, 1) 82.26%,
    rgba(230, 2, 0, 1) 100%
  );
  background: -ms-linear-gradient(
    -29.46deg,
    rgba(140, 0, 0, 1) 1.73%,
    rgba(141, 0, 0, 1) 2.71%,
    rgba(180, 1, 0, 1) 31.82%,
    rgba(207, 1, 0, 1) 58.67%,
    rgba(224, 2, 0, 1) 82.26%,
    rgba(230, 2, 0, 1) 100%
  );
  -ms-filter: "progid:DXImageTransform.Microsoft.gradient(startColorstr='#8C0000', endColorstr='#E60200' ,GradientType=0)";
  background: linear-gradient(
    119.46deg,
    rgba(140, 0, 0, 1) 1.73%,
    rgba(141, 0, 0, 1) 2.71%,
    rgba(180, 1, 0, 1) 31.82%,
    rgba(207, 1, 0, 1) 58.67%,
    rgba(224, 2, 0, 1) 82.26%,
    rgba(230, 2, 0, 1) 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#8C0000',endColorstr='#E60200' , GradientType=1);
}

.vodafone-container {
  height: 41.339vw;
  width: 58.465vw;
  position: relative;
  overflow: none;
  border-radius: 0.833vw;
  color: #ffffff;
  transform: scale(1.2);
}

.vodafone-container textarea {
  font-family: 'SofiaPro';
}

.vodafone-container textarea,
.vodafone-container input {
  font-family: inherit;
  font-size: 100%;
  line-height: 1.15;
  margin: 0;
}

html {
    line-height: 1.15;
    text-size-adjust: 100%;
}

.vodafone-canvas {
  height: 41.339vw;
  width: 58.465vw;
  /* border: 1px solid rgb(255 255 255 / 40%); */
  /* border-radius: 5px; */
  overflow: hidden;
  user-select: none;
}

.canvas-container {
  background: -moz-linear-gradient(
    0.82% 0.62% -29.46deg,
    rgba(140, 0, 0, 1) 1.73%,
    rgba(141, 0, 0, 1) 2.71%,
    rgba(180, 1, 0, 1) 31.82%,
    rgba(207, 1, 0, 1) 58.67%,
    rgba(224, 2, 0, 1) 82.26%,
    rgba(230, 2, 0, 1) 100%
  );
  background: -webkit-linear-gradient(
    -29.46deg,
    rgba(140, 0, 0, 1) 1.73%,
    rgba(141, 0, 0, 1) 2.71%,
    rgba(180, 1, 0, 1) 31.82%,
    rgba(207, 1, 0, 1) 58.67%,
    rgba(224, 2, 0, 1) 82.26%,
    rgba(230, 2, 0, 1) 100%
  );
  background: -webkit-gradient(
    linear,
    0.82% 0.62%,
    99.31% 99.51%,
    color-stop(0.0173, rgba(140, 0, 0, 1)),
    color-stop(0.0271, rgba(141, 0, 0, 1)),
    color-stop(0.3182, rgba(180, 1, 0, 1)),
    color-stop(0.5867, rgba(207, 1, 0, 1)),
    color-stop(0.8226, rgba(224, 2, 0, 1)),
    color-stop(1, rgba(230, 2, 0, 1))
  );
  background: -o-linear-gradient(
    -29.46deg,
    rgba(140, 0, 0, 1) 1.73%,
    rgba(141, 0, 0, 1) 2.71%,
    rgba(180, 1, 0, 1) 31.82%,
    rgba(207, 1, 0, 1) 58.67%,
    rgba(224, 2, 0, 1) 82.26%,
    rgba(230, 2, 0, 1) 100%
  );
  background: -ms-linear-gradient(
    -29.46deg,
    rgba(140, 0, 0, 1) 1.73%,
    rgba(141, 0, 0, 1) 2.71%,
    rgba(180, 1, 0, 1) 31.82%,
    rgba(207, 1, 0, 1) 58.67%,
    rgba(224, 2, 0, 1) 82.26%,
    rgba(230, 2, 0, 1) 100%
  );
  -ms-filter: "progid:DXImageTransform.Microsoft.gradient(startColorstr='#8C0000', endColorstr='#E60200' ,GradientType=0)";
  background: linear-gradient(
    119.46deg,
    rgba(140, 0, 0, 1) 1.73%,
    rgba(141, 0, 0, 1) 2.71%,
    rgba(180, 1, 0, 1) 31.82%,
    rgba(207, 1, 0, 1) 58.67%,
    rgba(224, 2, 0, 1) 82.26%,
    rgba(230, 2, 0, 1) 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#8C0000',endColorstr='#E60200' , GradientType=1);
}

.action-buttons.print-version {
  display: none;
}

.trends-container {
  position: relative;
  width: 58.465vw;
  padding: 3.937vw 3.937vw 3.937vw 2.937vw;
  height: 41.339vw;
  overflow: hidden;
  display: flex;
  box-sizing: border-box;
  background-image: url(./img/background_trends3.png);
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  background-position-x: 3.937vw;
}
.trends-container .title {
  color: #ffffff;
  font-family: SofiaPro, Helvetica, Arial, sans-serif;
  font-size: 2.083vw;
  margin: 1.042vw 0 1.563vw;
  font-weight: bold;
}
.trends-container .title span {
  display: flex;
  align-items: center;
  grid-auto-flow: column;
  grid-gap: 0.365vw;
}
.trends-container .sub-title {
  color: #ffffff;
  font-family: SofiaPro, Helvetica, Arial, sans-serif;
  font-size: 1.042vw;
  margin: 1.042vw 0 0;
  font-weight: bold;
}
.trends-container .trend-images-container {
  display: grid;
  grid-template-columns: repeat(3, 7.529vw);
  row-gap: 0.12vw;
  margin-bottom: 2.604vw;
  justify-content: center;
}
.trends-container .content {
  flex: 1;
  justify-content: center;
  align-items: center;
  display: flex;
  margin-left: 2.604vw;
}
.trend-image .react-draggable {
  cursor: grab;
}
.trend-image .react-draggable-dragging {
  cursor: grabbing;
}

.trend-image .image {
  position: absolute;
  width: 5.5vw;
  height: 5.5vw;
  border-radius: 50%;
}

.trend-item-container {
  display: block;
  margin: 0 auto;
  margin-bottom: 1.198vw;
}

.trend-item-container .trend-image-container {
  width: 5.5vw;
  height: 5.5vw;
  background-color: transparent;
  border-radius: 50%;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.trend-item .image {
  position: absolute;
  width: 5.5vw;
  height: 5.5vw;
  border-radius: 50%;
  left: 0;
  top: 0;
}

.vodafone {
  position: absolute;
  right: 1.302vw;
  bottom: 1vw;
  width: 6.51vw;
}

.info-button-container {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.info-button {
  width: 1.042vw;
  height: 1.042vw;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border-color: inherit;
  cursor: pointer;
}
.title .info-button {
  width: 1.042vw;
  height: 1.042vw;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border-color: inherit;
  cursor: pointer;
  margin-left: 0.321vw;
}
.info-button-description {
  background-color: #650201;
  padding: 0.781vw 1.042vw;
  border-radius: 0.521vw;
  font-size: 0.625vw;
  position: absolute;
  width: 12.5vw;
  font-weight: normal;
  display: none;
  top: 2.083vw;
  box-shadow: 0 0.156vw 0.313vw -0.104vw #1c0505;
  text-align: left;
  color: #ffffff;
}

.info-button-container.inverse .info-button-description {
  top: initial;
  bottom: 2.083vw;
}

.info-button-description.open {
  display: block;
  z-index: 999999;
}

.vision-container {
  position: relative;
  width: 58.465vw;
  padding: 3.937vw;
  height: 41.339vw;
  overflow: hidden;
  display: flex;
  box-sizing: border-box;
  background-image: url(./img/background_vision.png);
  background-size: cover;
  background-position-y: center;
  background-position-x: center;
  background-repeat: no-repeat;
}
.vision-container .title {
  color: #ffffff;
  font-family: SofiaPro, Helvetica, Arial, sans-serif;
  font-size: 1.363vw;
  margin: 1.042vw 0 1.563vw;
  font-weight: bold;
  position: relative;
  margin-left: -2.5vw;
}
.vision-container .title span {
  display: flex;
  align-items: center;
  grid-auto-flow: column;
  grid-gap: 0.365vw;
}
.vision-container .sub-title {
  color: #ffffff;
  font-family: SofiaPro, Helvetica, Arial, sans-serif;
  font-size: 1.042vw;
  margin: 1.042vw 0 1.563vw;
  font-weight: bold;
  position: absolute;
  text-align: center;
  box-sizing: content-box;
  display: grid;
  align-items: center;
  grid-auto-flow: column;
  grid-gap: 0.365vw;
}

.vision-container .sub-title .vision-input-container {
  margin: 0 0 1.563vw;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.vision-container .sub-title .vision-input {
  color: #740102;
  font-weight: bold;
  background-color: transparent;
  border: none;
  text-align: center;
  font-size: 0.833vw;
  width: 6.75vw;
  display: flex;
  align-items: center;
  resize: none;
  overflow: hidden;
  padding: 0.521vw 0.521vw 0.521vw 0.521vw;
  border-radius: 1.5vw 1.5vw 0 0;
}

.draggable-input-container {
  width: 5.5vw;
  height: 5.5vw;
  background-color: #E60000;
  border-radius: 50%;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.draggable-input {
  font-family: SofiaPro, Helvetica, Arial, sans-serif;
  color: #ffffff;
  margin: 0 auto;
  background-color: transparent;
  border: none;
  padding: 0.521vw 0.521vw 0.521vw 0.521vw;
  border-radius: 5.208vw;
  height: 5.521vw;
  text-align: center;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  resize: none;
  display: inline-block;
  vertical-align: top;
}

textarea.draggable-input {
  font-size: 0.55vw;
  font-weight: 600;
  font-family: SofiaPro, Helvetica, Arial, sans-serif;
}

textarea.draggable-input::-webkit-scrollbar,
.draggable-input-container::-webkit-scrollbar {
  display: none !important;
}

textarea.draggable-input::-webkit-input-placeholder {
  color: white;
}

.vision-input.print-version {
  width: 7.75vw !important;
  padding-top: 0 !important;
  border-radius: 0 !important;
}

.vision-container .sub-title .vision-input::-webkit-input-placeholder {
  font-family: SofiaPro, Helvetica, Arial, sans-serif;
  color: #740102;
  font-weight: bold;
  border-color: '#740102';
}

.vision-container .slogan {
  color: #740102;
  font-family: SofiaPro, Helvetica, Arial, sans-serif;
  font-size: 1.042vw;
  margin: 1.042vw 0 1.563vw;
  font-weight: bold;
  position: absolute;
  left: 0;
  bottom: 2.083vw;
  right: 0;
  text-align: center;
  box-sizing: content-box;
}

.vision-container .description {
  color: #740102;
  font-family: SofiaPro, Helvetica, Arial, sans-serif;
  font-size: 0.521vw;
  margin: 1.042vw 0 1.563vw;
  font-weight: bold;
  position: absolute;
  left: 0;
  bottom: 0.521vw;
  right: 0;
  text-align: center;
  box-sizing: content-box;
}

.round-input .container {
  color: #ffffff;
  font-family: SofiaPro, Helvetica, Arial, sans-serif;
  font-size: 0.833vw;
  margin: 1.042vw 0 1.563vw;
  font-weight: bold;
  position: absolute;
  width: 6.563vw;
  height: 6.563vw;
  display: flex;
  align-items: center;
}
.round-input .input {
  font-family: SofiaPro, Helvetica, Arial, sans-serif;
  color: #ffffff;
  margin: 0 auto;
  background-color: transparent;
  border: none;
  padding: 0.521vw 0.521vw 0.521vw 0.521vw;
  border-radius: 5.208vw;
  height: 5.521vw;
  text-align: center;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  resize: none;
  display: inline-block;
  vertical-align: top;
}

.round-input .input::placeholder {
  color: #740102;
}
.round-input .input::focus-visible {
  border: unset !important;
}
.round-input .input::-webkit-scrollbar {
  display: none;
}

.long-input .container,
.long-input-bullet .container {
  color: #740102;
  font-family: SofiaPro, Helvetica, Arial, sans-serif;
  font-size: 0.521vw;
  margin: 1.042vw 0 1.563vw;
  font-weight: bold;
  position: absolute;
  text-align: center;
  box-sizing: content-box;
}

.long-input-bullet .container {
  color: white;
  display: flex;
  flex-direction: row;
  font-size: 0.521vw;
}

.long-input .input {
  color: #740102;
  margin: 0 auto;
  background-color: transparent;
  border: none;
  padding: 0.521vw 0.521vw 0.521vw 1.302vw;
  border-radius: 0.521vw;
  height: 1.042vw;
  text-align: center;
  box-sizing: content-box;
  resize: none;
  overflow: hidden;
  padding-top: 0;
  padding-bottom: 0
}

.canvas-print .long-input .input {
  font-size: 0.521vw;
}

.long-input-bullet {
  position: absolute;
}

.long-input-bullet label {
  margin-right: 0.5vw;
  position: relative;
  margin-top: 0.3vw;
}

.long-input-bullet .input {
  color: white;
  margin: 0 auto;
  background-color: transparent;
  border: none;
  border-radius: 0;
  height: 1.042vw;
  text-align: left;
  box-sizing: content-box;
  resize: none;
}

.long-input .input ::-webkit-scrollbar,
.long-input-bullet .input ::-webkit-scrollbar{
  display: none;
}

.canvas-print {
  width: 100%;
  height: 100%;
}