@font-face {
  font-family: 'fontello';
  src: url('../font/fontello.eot?64362844');
  src: url('../font/fontello.eot?64362844#iefix') format('embedded-opentype'),
       url('../font/fontello.woff2?64362844') format('woff2'),
       url('../font/fontello.woff?64362844') format('woff'),
       url('../font/fontello.ttf?64362844') format('truetype'),
       url('../font/fontello.svg?64362844#fontello') format('svg');
  font-weight: normal;
  font-style: normal;
}
/* Chrome hack: SVG is rendered more smooth in Windozze. 100% magic, uncomment if you need it. */
/* Note, that will break hinting! In other OS-es font will be not as sharp as it could be */
/*
@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: 'fontello';
    src: url('../font/fontello.svg?64362844#fontello') format('svg');
  }
}
*/
[class^="icon-"]:before, [class*=" icon-"]:before {
  font-family: "fontello";
  font-style: normal;
  font-weight: normal;
  speak: never;

  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  margin-right: .2em;
  text-align: center;
  /* opacity: .8; */

  /* For safety - reset parent styles, that can break glyph codes*/
  font-variant: normal;
  text-transform: none;

  /* fix buttons height, for twitter bootstrap */
  line-height: 1em;

  /* Animation center compensation - margins should be symmetric */
  /* remove if not needed */
  margin-left: .2em;

  /* you can be more comfortable with increased icons size */
  /* font-size: 120%; */

  /* Font smoothing. That was taken from TWBS */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  /* Uncomment for 3D effect */
  /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
}

.icon-ic_attendees:before { content: '\e800'; } /* '' */
.icon-ic_close:before { content: '\e801'; } /* '' */
.icon-ic_chat:before { content: '\e802'; } /* '' */
.icon-ic_linkedin:before { content: '\e803'; } /* '' */
.icon-ic_live:before { content: '\e804'; } /* '' */
.icon-ic_menu:before { content: '\e805'; } /* '' */
.icon-ic_phone:before { content: '\e806'; } /* '' */
.icon-ic_search:before { content: '\e807'; } /* '' */
.icon-ic_warning:before { content: '\e808'; } /* '' */
.icon-ic_nopic:before { content: '\e809'; } /* '' */
.icon-ic_shoutout:before { content: '\e80a'; } /* '' */
.icon-ic_arrowback:before { content: '\e80b'; } /* '' */
.icon-ic_arrow:before { content: '\e80c'; } /* '' */
.icon-ic_conversations:before { content: '\e80d'; } /* '' */
.icon-ic_play:before { content: '\e80e'; } /* '' */
.icon-ic_slido:before { content: '\e80f'; } /* '' */
.icon-ic_help:before { content: '\e810'; } /* '' */
.icon-ic_send:before { content: '\e811'; } /* '' */
.icon-ic_videocall:before { content: '\e812'; } /* '' */
.icon-plus:before { content: '\e813'; } /* '' */
.icon-clock:before { content: '\e814'; } /* '' */
.icon-ic_calendar:before { content: '\e815'; } /* '' */
.icon-ok:before { content: '\e816'; } /* '' */
.icon-cancel:before { content: '\e817'; } /* '' */
.icon-ic_declinecall:before { content: '\e818'; } /* '' */
.icon-resize-full:before { content: '\e819'; } /* '' */
.icon-bell-alt:before { content: '\f0f3'; } /* '' */
