/**
 * @license
 * MyFonts Webfont Build ID 4110767, 2021-06-28T09:45:19-0400
 * 
 * The fonts listed in this notice are subject to the End User License
 * Agreement(s) entered into by the website owner. All other parties are 
 * explicitly restricted from using the Licensed Webfonts(s).
 * 
 * You may obtain a valid license at the URLs below.
 * 
 * Webfont: SofiaPro-UltraLight by Mostardesign
 * URL: https://www.myfonts.com/fonts/mostardesign/sofia-pro/ultra-light/
 * 
 * Webfont: SofiaPro-UltraLightitalic by Mostardesign
 * URL: https://www.myfonts.com/fonts/mostardesign/sofia-pro/ultra-light-italic/
 * 
 * Webfont: SofiaPro-ExtraLight by Mostardesign
 * URL: https://www.myfonts.com/fonts/mostardesign/sofia-pro/extra-light/
 * 
 * Webfont: SofiaPro-ExtraLightitalic by Mostardesign
 * URL: https://www.myfonts.com/fonts/mostardesign/sofia-pro/extra-light-italic/
 * 
 * Webfont: SofiaPro-Light by Mostardesign
 * URL: https://www.myfonts.com/fonts/mostardesign/sofia-pro/light/
 * 
 * Webfont: SofiaPro-Lightitalic by Mostardesign
 * URL: https://www.myfonts.com/fonts/mostardesign/sofia-pro/light-italic/
 * 
 * Webfont: SofiaPro-Regular by Mostardesign
 * URL: https://www.myfonts.com/fonts/mostardesign/sofia-pro/regular/
 * 
 * Webfont: SofiaPro-Regularitalic by Mostardesign
 * URL: https://www.myfonts.com/fonts/mostardesign/sofia-pro/regular-italic/
 * 
 * Webfont: SofiaPro-Medium by Mostardesign
 * URL: https://www.myfonts.com/fonts/mostardesign/sofia-pro/medium/
 * 
 * Webfont: SofiaPro-Mediumitalic by Mostardesign
 * URL: https://www.myfonts.com/fonts/mostardesign/sofia-pro/medium-italic/
 * 
 * Webfont: SofiaPro-SemiBold by Mostardesign
 * URL: https://www.myfonts.com/fonts/mostardesign/sofia-pro/semi-bold/
 * 
 * Webfont: SofiaPro-SemiBolditalic by Mostardesign
 * URL: https://www.myfonts.com/fonts/mostardesign/sofia-pro/semi-bold-italic/
 * 
 * Webfont: SofiaPro-Bold by Mostardesign
 * URL: https://www.myfonts.com/fonts/mostardesign/sofia-pro/bold/
 * 
 * Webfont: SofiaPro-Bolditalic by Mostardesign
 * URL: https://www.myfonts.com/fonts/mostardesign/sofia-pro/bold-italic/
 * 
 * Webfont: SofiaPro-Black by Mostardesign
 * URL: https://www.myfonts.com/fonts/mostardesign/sofia-pro/black/
 * 
 * Webfont: SofiaPro-Blackitalic by Mostardesign
 * URL: https://www.myfonts.com/fonts/mostardesign/sofia-pro/black-italic/
 * 
 * 
 * Webfonts copyright: Copyright © Olivier Gourvat - Mostardesign Type Foundry, 2020. All rights reserved.
 * 
 * © 2021 MyFonts Inc
*/

@font-face {
  font-family: "SofiaPro";
  font-style: normal;
  font-weight: 700;
  src: url('SofiaProBold/font.woff2') format('woff2'), url('SofiaProBold/font.woff') format('woff');
}

@font-face {
  font-family: "SofiaPro";
  font-style: italic;
  font-weight: 700;
  src: url('SofiaProBolditalic/font.woff2') format('woff2'), url('SofiaProBolditalic/font.woff') format('woff');
}

@font-face {
  font-family: "SofiaPro";
  font-style: normal;
  font-weight: 600;
  src: url('SofiaProSemiBold/font.woff2') format('woff2'), url('SofiaProSemiBold/font.woff') format('woff');
}

@font-face {
  font-family: "SofiaPro";
  font-style: italic;
  font-weight: 600;
  src: url('SofiaProSemiBolditalic/font.woff2') format('woff2'), url('SofiaProSemiBolditalic/font.woff') format('woff');
}

@font-face {
  font-family: "SofiaPro";
  font-style: normal;
  font-weight: 500;
  src: url('SofiaProMedium/font.woff2') format('woff2'), url('SofiaProMedium/font.woff') format('woff');
}

@font-face {
  font-family: "SofiaPro";
  font-style: italic;
  font-weight: 500;
  src: url('SofiaProMediumitalic/font.woff2') format('woff2'), url('SofiaProMediumitalic/font.woff') format('woff');
}

@font-face {
  font-family: "SofiaPro";
  font-style: normal;
  font-weight: 400;
  src: url('SofiaProRegular/font.woff2') format('woff2'), url('SofiaProRegular/font.woff') format('woff');
}

@font-face {
  font-family: "SofiaPro";
  font-style: italic;
  font-weight: 400;
  src: url('SofiaProRegularitalic/font.woff2') format('woff2'), url('SofiaProRegularitalic/font.woff') format('woff');
}
